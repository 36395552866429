import PropTypes from 'prop-types';
import React from 'react';
import FishingPageLivingItem from '../FishingPageLivingItem';

function GuidePackageItem(props) {
  const {
    name,
    images,
    days,
    description,
    price,
    expanded,
    toggleExpanded,
  } = props;
  const daysText = days > 1 ? 'dagar' : 'dag';
  return (
    <FishingPageLivingItem
      title={name}
      secondaryTitle={`${days} ${daysText}`}
      images={images}
      price={`${price} kr`}
      description={description}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
    />
  );
}

export default GuidePackageItem;

GuidePackageItem.propTypes = {
  days: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};
