import React from 'react';
import Stack from '../Stack';
import Text from '../Text';

function PageSection({ title, children, space = 3 }) {
  return (
    <Stack space={space}>
      <Text.H2>{title}</Text.H2>
      {children}
    </Stack>
  );
}

export default PageSection;
