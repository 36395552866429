import PropTypes from 'prop-types';
import React from 'react';
import FishingPageLivingItem from '../FishingPageLivingItem';

function HousingItem(props) {
  const {
    name,
    shared,
    images,
    capacity,
    description,
    price,
    expanded,
    toggleExpanded,
  } = props;

  const sharedText = shared ? 'Delat boende' : 'Eget boende';
  const title = `${name} | ${sharedText}`;
  const beds = capacity > 1 ? 'Bäddar' : 'Bädd';

  return (
    <FishingPageLivingItem
      title={title}
      secondaryTitle={`${capacity} ${beds}`}
      description={description}
      price={`${price} kr/natt`}
      images={images}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
    />
  );
}

HousingItem.propTypes = {
  capacity: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  shared: PropTypes.bool.isRequired,
};

export default HousingItem;
