import React from 'react';
import { Link, Grid, Flex, Box } from 'theme-ui';
import { lighten } from '@theme-ui/color';
import Icon from '../components/Icon';
import Seo from '../components/Seo';
import UspList from '../components/UspList';
import PageSection from '../components/PageSection';
import Stack from '../components/Stack';
import Map, { Marker } from '../components/Map';
import ContentBox from '../components/ContentBox';
import Text from '../components/Text';
import Button from '../components/Button';
import Layout from '../global/Layout';
import Ad from '../components/Ad';
import ImageBox from '../components/ImageBox';
import FishingPageLivingList from '../components/FishingPageLivingList';

function Usp(props) {
  const { icon, heading, value } = props;
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: ['center', 'flex-start'],
        width: ['100%'],
      }}
    >
      <Flex
        sx={{
          flexGrow: 1,
          flexDirection: 'row-reverse',
          justifyContent: 'center',
          alignItems: 'center',
          height: ['1.8rem', '4rem'],
          width: ['1.8rem', '4rem'],
          position: 'relative',
        }}
      >
        <Box
          sx={{
            ml: 1,
            width: ['70%', '50%'],
            height: ['70%', '50%'],
            filter: 'opacity(0.7)',
            backgroundImage: `url(${icon})`,
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Flex>
      <Box>
        <Text.P
          sx={{
            color: 'text',
            fontSize: [0, null, 1],
          }}
        >
          {value} {heading}
        </Text.P>
      </Box>
    </Flex>
  );
}

function getMapMarker(type, icons) {
  return icons.find((z) => z.name === `${type}_pin`);
}

function FishingPage(props) {
  const {
    pageContext: {
      amenities: allAmenities,
      usps,
      type,
      icons,
      data: {
        name,
        description,
        amenities: availableAmenities,
        housing = [],
        guidePackage = [],
        image = { url: null },
        location,
        extraImages = [],
        websiteLink,
      },
    },
  } = props;
  const markerIcon = getMapMarker(type, icons);
  const living = type === 'camp' ? housing : guidePackage;
  return (
    <Layout showNavigateBackInHeader>
      <Seo
        title={name}
        description={description.slice(0, 255)}
        image={image?.url}
      />
      <Box sx={{ bg: 'white' }}>
        <Box sx={{ pt: [2, 4], textAlign: 'center' }}>
          <Stack space={[3, 4]}>
            <ContentBox>
              <Stack space={1}>
                <Text.H1>{name}</Text.H1>
                <Flex sx={{ alignItems: 'flex-end', justifyContent: 'center' }}>
                  <Icon nudge="-2px" size="xsmall" icon="location" />
                  <Link
                    sx={{ textDecoration: 'underline', color: 'text' }}
                    href="#map"
                  >
                    <Text.P sx={{ ml: 1, color: 'accent-5' }}>
                      {location.address}
                    </Text.P>
                  </Link>
                </Flex>
              </Stack>
            </ContentBox>
            <ImageBox images={[image?.url, ...extraImages.map((x) => x.url)]} />
          </Stack>
        </Box>
        <Grid columns={[1, '2fr 1fr']}>
          <ContentBox>
            <Box
              sx={{
                flexShrink: 0,
                display: ['flex', 'none'],
                position: ['fixed', 'static'],
                bottom: '0',
                right: '0',
                boxShadow: (theme) =>
                  `-1px -3px 6px 0px ${lighten('accent-4', 0.26)(theme)}`,
                left: 0,
                p: 3,
                bg: 'white',
                justifyContent: 'center',
                zIndex: 13,
              }}
            >
              <Link
                href={websiteLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button as="span" size="medium" variant="success">
                <b>Boka nu</b>
                </Button>
              </Link>
            </Box>
            <Stack space={[3, 4]}>
              <Grid columns={[4]} gap={[0, null, 2]}>
                {usps.filter(Boolean).map(({ icon, heading: usp, value }) => (
                  <Usp key={usp} heading={usp} value={value} icon={icon} />
                ))}
              </Grid>

              <Flex sx={{ width: ['100%'], justifyContent: 'space-between' }}>
                <Flex
                  sx={{
                    justifyContent: 'space-between',
                    flex: 1,
                    flexDirection: ['column', 'row'],
                  }}
                >
                  <Box sx={{ width: ['100%'] }}>
                    <Text.P sx={{ whiteSpace: 'pre-line' }}>
                      {description}
                    </Text.P>
                  </Box>
                </Flex>
              </Flex>
              <PageSection title="Bekvämligheter">
                <Flex sx={{ justifyContent: 'space-between' }}>
                  <Box sx={{ width: ['100%'] }}>
                    <Grid
                      sx={{ gridRowGap: 1, alignContent: 'center' }}
                      columns={[2, 3]}
                    >
                      <UspList
                        size="large"
                        allUsps={allAmenities}
                        availableUsps={availableAmenities}
                      />
                    </Grid>
                  </Box>
                </Flex>
              </PageSection>
              <PageSection title={type === 'camp' ? 'Boende' : 'Guidepaket'}>
                <FishingPageLivingList type={type} items={living} />
              </PageSection>
            </Stack>
          </ContentBox>
          <ContentBox>
            <Stack space={5}>
              <Box
                sx={{ display: ['none', 'flex'], justifyContent: 'flex-end' }}
              >
                <Link
                  href={websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button as="span" size="large" variant="success">
                  <b>Boka nu</b>
                  </Button>
                </Link>
              </Box>
              <Flex sx={{ justifyContent: ['center', 'flex-end'] }}>
                <Ad />
              </Flex>
            </Stack>
          </ContentBox>
        </Grid>
        <ContentBox>
          <Stack>
            <Text.H2 name="map">Karta </Text.H2>
            <Box sx={{ height: '300px' }}>
              <Map
                center={{ lat: location.latitude, lng: location.longitude }}
                zoom={12}
              >
                <Marker
                  onClick={null}
                  icon={markerIcon}
                  lat={location.latitude}
                  lng={location.longitude}
                />
              </Map>
            </Box>
          </Stack>
        </ContentBox>
      </Box>
    </Layout>
  );
}
export default FishingPage;
