import React from 'react';
import { Link, Flex } from 'theme-ui';
import config from '../../config';

function Ad() {
  return (
    <Link href={config.ad.targetUrl} target="_blank" rel="noopener noreferrer">
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '40vh',
          width: '95vw',
          maxWidth: '100%',
          bg: 'accent-3',
          backgroundImage: `url("${config.ad.imageUrl}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'none',
          backgroundSize: 'cover',
        }}
      />
    </Link>
  );
}

export default Ad;
