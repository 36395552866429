import React, { useState } from 'react';
import { Grid, Box } from 'theme-ui';
import GuidePackageItem from '../GuidePackageItem';
import HousingItem from '../HousingItem';

function FishingPageLivingList(props) {
  const { items, type } = props;
  const [expanded, setExpanded] = useState({});

  function toggleExpanded(id) {
    setExpanded({
      [id]: !expanded[id] || false,
    });
  }
  let ListComponent;
  if (type === 'guide') {
    ListComponent = GuidePackageItem;
  } else if (type === 'camp') {
    ListComponent = HousingItem;
  } else {
    ListComponent = null;
  }

  return (
    <Grid columns={[1]} sx={{ gridRowGap: 4 }}>
      <Box sx={{ width: '100%' }}>
        <Grid sx={{ gridRowGap: 3 }}>
          {items.map((x) => (
            <ListComponent
              key={x.id}
              {...x}
              expanded={expanded[x.id] || false}
              toggleExpanded={() => toggleExpanded(x.id)}
              images={x.images.map((p) => p.url)}
            />
          ))}
        </Grid>
      </Box>
    </Grid>
  );
}

export default FishingPageLivingList;
