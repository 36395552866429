import React, { useState } from 'react';
import { Flex, Box, Image } from 'theme-ui';
import FsLightbox from 'fslightbox-react';
import Icon from '../Icon';
import Text from '../Text';

const br = {
  borderRadius: '5px',
};
function ImageBox({ images }) {
  const [toggler, setToggler] = useState(false);
  return (
    <>
      <FsLightbox type="image" sources={images} toggler={toggler} />
      <Box
        sx={{
          display: ['block', 'flex'],
          width: '100%',
          flexDirection: 'row-reverse',
          position: 'relative',
          minHeight: ['30vh', '37rem'],
          height: ['30vh', '37rem'],
          cursor: 'pointer',
        }}
        onClick={() => setToggler(!toggler)}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            height: '100%',
            width: '100%',
            flexShrink: 1,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: '100%',
              position: 'absolute',
            }}
          >
            <Image
              sx={{
                ...br,
                justifySelf: 'stretch',
                minHeight: '100%',
                height: '100%',
                objectFit: 'cover',
                width: ['100%'],
                flexShrink: 0,
                bg: 'accent-4',
              }}
              src={images[0]}
            />
            <Flex
              onClick={() => setToggler(!toggler)}
              sx={{
                display: [null, 'none'],
                justifyContent: 'center',
                alignContent: 'center',
                flex: 1,
                position: 'absolute',
                bottom: '5px',
                right: 15,
                p: 1,
                opacity: 0.8,
                ...br,
                bg: 'white',
              }}
            >
              <Text.P>Visa alla bilder</Text.P>
            </Flex>
          </Box>
        </Flex>
        <Box
          sx={{
            display: ['none', 'flex'],
            width: '100%',
            flex: '1 1 40%',
            mr: 2,
            flexDirection: 'column',
          }}
        >
          <Image
            sx={{
              ...br,
              mb: 2,
              objectFit: 'cover',
              height: 'calc(37rem/2)',
              width: '100%',
            }}
            src={images[1]}
          />
          <div
            style={{
              height: 'calc(37rem/2)',
              position: 'relative',
            }}
          >
            <Image
              sx={{
                ...br,
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
              src={images[2]}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 4,
                transform: 'translateX(-50%) translateY(-50%)',
              }}
              onClick={() => setToggler(!toggler)}
            >
              <Icon
                sx={{
                  color: 'white',
                }}
                size="xlarge"
                icon="gallery"
              />
            </Box>
            <Box
              sx={{
                zIndex: 1,
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.5)',
                width: '100%',
                height: '100%',
              }}
            >
              <Text.Span sx={{ mt: 5 }}>Se alla bilder</Text.Span>
            </Box>
          </div>
        </Box>
      </Box>
    </>
  );
}
export default ImageBox;
